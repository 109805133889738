<template>
  <div style="margin: 0; padding: 0">
    <PageHeader subtitle="Overview" title="My Profile" />

    <div class="row">
      <div class="col-lg-4">
        <div class="card card-small mb-4 pt-3">
          <div class="card-header border-bottom text-center">
            <div class="mb-3 mx-auto">
              <img class="rounded-circle" src="../../assets/images/avatar.jpg" alt="User Avatar" width="110" />
            </div>
            <h4 class="mb-0">{{ name }}</h4>
            <span class="text-muted d-block mb-2">{{ myuser.role || 'Role unknown' }}</span>
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item p-4">
              <strong class="text-muted d-block mb-2">Description</strong>
              <span>{{ myuser.description || '' }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="card card-small mb-4">
          <div class="card-header border-bottom">
            <h6 class="m-0">Account Details</h6>
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item p-3">
              <div class="row">
                <div class="col">
                  <b-form @submit.stop.prevent @submit="updateUser">
                    <div class="form-row">
                      <!-- Name -->
                      <b-form-group id="name-group" label-for="name" label="Full Name" description="" class="col-md-6">
                        <b-form-input id="fullname" v-model="myuser.fullname" type="text" placeholder="Full name" aria-describedby="input-live-name-feedback" :state="errors.fullname.status" required>
                        </b-form-input>

                        <!-- This will only be shown if the preceding input has an invalid state -->
                        <b-form-invalid-feedback id="input-live-name-feedback">
                          {{ errors.fullname.message || 'Full name is required. E.g. Firstname Surname' }}
                        </b-form-invalid-feedback>
                      </b-form-group>

                      <!-- Mobile -->
                      <b-form-group id="mobile-group" label-for="mobile" label="Mobile" description="" class="col-md-6">
                        <b-form-input id="mobile" v-model="myuser.mobile" type="text" placeholder="Phone number" aria-describedby="input-live-mobile-feedback" :state="errors.mobile.status" required>
                        </b-form-input>

                        <!-- This will only be shown if the preceding input has an invalid state -->
                        <b-form-invalid-feedback id="input-live-mobile-feedback">
                          {{ errors.mobile.message || 'Phone number must be a valid Ghana phone number' }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>

                    <div class="form-row">
                      <!-- Email -->
                      <b-form-group id="email-group" label-for="email" label="Email" description="" class="col-md-6">
                        <b-form-input
                          id="email"
                          v-model="myuser.emailAddress"
                          type="email"
                          placeholder="Email address"
                          aria-describedby="input-live-email-feedback"
                          :state="errors.email.status"
                          required
                        >
                        </b-form-input>

                        <!-- This will only be shown if the preceding input has an invalid state -->
                        <b-form-invalid-feedback id="input-live-email-feedback">
                          {{ errors.email.message || 'Email is invalid.' }}
                        </b-form-invalid-feedback>
                      </b-form-group>

                      <!-- Password -->
                      <b-form-group id="pass-group" label-for="pass" label="Password" description="" class="col-md-6">
                        <b-form-input id="pass" v-model="myuser.password" type="password" placeholder="Password" aria-describedby="input-live-pass-feedback" :state="errors.password.status">
                        </b-form-input>

                        <!-- This will only be shown if the preceding input has an invalid state -->
                        <b-form-invalid-feedback id="input-live-pass-feedback">
                          {{ errors.password.status || 'Invalid password. Password must be 6 or more characters' }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>

                    <div class="form-row">
                      <!-- Description -->
                      <b-form-group id="desc-group" label-for="description" label="Description" description="" class="col-md-12">
                        <b-form-textarea
                          id="description"
                          rows="5"
                          v-model="myuser.description"
                          placeholder="Enter a description of the user"
                          aria-describedby="input-live-desc-feedback"
                          :state="errors.description.status"
                          required
                        >
                        </b-form-textarea>
                        <!-- This will only be shown if the preceding input has an invalid state -->
                        <b-form-invalid-feedback id="input-live-desc-feedback">
                          {{ errors.description.message || 'Description is required' }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>

                    <b-button type="submit" variant="primary" :disabled="!canSubmit"> <b-spinner v-show="busy" small></b-spinner>Update Account </b-button>
                  </b-form>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import is from 'is_js'

import PageHeader from '../../containers/PageHeader'
import userService from '../../api/user'

export default {
  name: 'UserProfile',
  components: {
    PageHeader
  },
  data() {
    return {
      myuser: {
        userID: '',
        fullname: '',
        emailAddress: '',
        password: '',
        mobile: '',
        description: '',
        role: ''
      },
      errors: {
        fullname: {
          status: null,
          message: '',
          validate: function (v) {
            return !is.empty(v) && v.indexOf(' ') !== -1 ? null : false
          }
        },
        email: {
          status: null,
          message: '',
          validate: function (v) {
            return is.email(v) ? null : false
          }
        },
        mobile: {
          status: null,
          message: '',
          validate: function (v) {
            return is.startWith(v, '0') && v.length >= 10 ? null : false
          }
        },
        password: {
          status: null,
          message: '',
          validate: function (v) {
            return is.undefined(v) || is.empty(v) ? null : v.length > 5
          }
        },
        description: {
          status: null,
          message: '',
          validate: function (v) {
            return !is.empty(v) ? null : false
          }
        }
      },
      busy: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      isLoggedIn: 'isLoggedIn'
    }),
    canSubmit: function () {
      for (let i in this.errors) {
        if (this.errors[i].status === false) {
          return false
        }
      }
      return true
    },
    name: function () {
      return this.myuser === null ? '' : this.myuser.fullname
    }
  },
  watch: {
    myuser: {
      handler(val) {
        for (let field in val) {
          if (is.not.undefined(this.errors[field])) {
            this.errors[field].status = this.errors[field].validate(val[field])
          }
        }
      },
      deep: true
    }
  },
  created() {
    for (let x in this.myuser) {
      this.myuser[x] = this.user[x]
    }
  },
  methods: {
    updateUser() {
      let self = this
      this.busy = true

      if (is.undefined(this.myuser.password) || is.empty(this.myuser.password)) {
        delete this.myuser.password
      }
      this.myuser.email = this.myuser.emailAddress
      userService
        .updateUser(this.myuser)
        .then((response) => {
          this.$store.dispatch('setUser', response).then(() => {
            self.showSuccess('User update', 'User has been successfully saved.')
            self.busy = false
          })
        })
        .catch((error) => {
          this.busy = false
          if (is.existy(error.errors)) {
            this.errors = this.handleFormError(error.errors, this.errors)
          } else {
            this.handleError(error)
          }
        })
    }
  }
}
</script>
