<template>
  <!-- Page Header -->
  <div class="page-header row no-gutters py-4 mb-2">
    <div class="col-12 text-center text-sm-left mb-0">
      <h3 class="page-title">{{ title }}</h3>
      <span class="text-uppercase page-subtitle">{{ subtitle }}</span>
    </div>
  </div>
  <!-- End Page Header -->
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' }
  }
}
</script>
